import React, { useEffect, useState, useRef } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { setShares } from "../../redux/filters/actions";
import { useLocation, useSearchParams } from "react-router-dom";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import InfoIconTooltip from "./InfoIconTooltip";

const SharesFilter = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation();
  const dropdownRef = useRef(null);

  const sharesUrl = searchParam.get("shares");
  const sharesArray = sharesUrl ? sharesUrl.split(",") : [];

  const selected_shares = useSelector((state) => state.shares);
  const [rangeValues, setRangeValues] = useState(
    selected_shares || [null, null]
  );
  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);
  const [toDropdownOpen, setToDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const convertToNumeric = (value) => {
    const conversions = {
      "1k": 1000,
      "5k": 5000,
      "10k": 10000,
      "25k": 25000,
      "50k": 50000,
      "100k": 100000,
      "250k": 250000,
      "500k": 500000,
      ">1m": 1000000,
      "<1m": 1000000,
      Clear: null, // Return null for the "Clear" option
    };

    return conversions[value] !== undefined
      ? conversions[value]
      : parseInt(value);
  };

  const handleFromChange = (value) => {
    const newFromValue = value === "Clear" ? null : convertToNumeric(value);
    const newRangeValues = [newFromValue, rangeValues[1]];
    setRangeValues(newRangeValues);
    setFromDropdownOpen(false);
    updateFilterAndUrl(newRangeValues);
  };

  const handleToChange = (value) => {
    const newToValue = value === "Clear" ? null : convertToNumeric(value);
    const newRangeValues = [rangeValues[0], newToValue];
    setRangeValues(newRangeValues);
    setToDropdownOpen(false);
    updateFilterAndUrl(newRangeValues);
  };

  const updateFilterAndUrl = (newRangeValues) => {
    const sanitizedValues = newRangeValues.filter(
      (value) => value !== null && value !== undefined && value !== ""
    );

    dispatch(setShares(sanitizedValues));

    if (sanitizedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("shares", sanitizedValues.join(","));
        return searchParams;
      });
    } else {
      setSearchParam((searchParams) => {
        searchParams.delete("shares");
        return searchParams;
      });
    }
  };

  useEffect(() => {
    const updatedRangeValues = [
      sharesArray[0] ? parseInt(sharesArray[0]) : null,
      sharesArray[1] ? parseInt(sharesArray[1]) : null,
    ];
    setRangeValues(updatedRangeValues);
    dispatch(setShares(updatedRangeValues.filter(Boolean)));
  }, [location.search, dispatch]);

  const fromOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    ">1m",
  ];
  const toOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    "<1m",
  ];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="Shares flex justify-start">
      <div className="relative w-full">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex items-center bg-white filterTextColor"
        >
          <span
            className={`${
              isDropdownOpen ? "font-semibold" : ""
            } flex items-center`}
          >
            Shares
            <span className={`flex items-center`}>
              {isDropdownOpen ? (
                <RxCross2 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2 text-lg" />
              )}
            </span>
          </span>
        </button>
        <ul
          className={`absolute w-[250px] z-50 p-5 bg-white rounded-lg shadow-lg ${
            isDropdownOpen ? "block" : "hidden"
          }`}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <MdCampaign className="text-2xl" /> Influencer
              <InfoIconTooltip
                infoContent={`Identify influencers by the number of shares. You can filter by the number of shares using predefined options or clear the filters.`}
              />
            </div>
            <div className="flex gap-2 w-full">
              <div className="relative">
                <input
                  type="text"
                  className="w-full py-3 rounded-md border-[0.6px] border-[#D9D9D9]"
                  placeholder="From"
                  value={rangeValues[0] !== null ? rangeValues[0] : ""}
                  onClick={() => setFromDropdownOpen(!fromDropdownOpen)}
                  readOnly
                />
                {fromDropdownOpen && (
                  <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                    {fromOptions.map((option) => (
                      <div
                        key={option}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleFromChange(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {rangeValues[0] !== 1000000 && (
                <div className="relative">
                  <input
                    type="text"
                    className="w-full py-3 rounded-md border-[0.6px] border-[#D9D9D9]"
                    placeholder="To"
                    value={rangeValues[1] !== null ? rangeValues[1] : ""}
                    onClick={() => setToDropdownOpen(!toDropdownOpen)}
                    readOnly
                  />
                  {toDropdownOpen && (
                    <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                      {toOptions.map((option) => (
                        <div
                          key={option}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                          onClick={() => handleToChange(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default SharesFilter;