'use client';
import { Button, Spinner } from 'flowbite-react'
import React from 'react'
import { MdDone } from 'react-icons/md'
import { FcExport } from 'react-icons/fc'
import { useState } from 'react'
import { Avatar } from 'flowbite-react';
import Papa from 'papaparse';
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { CiSearch } from 'react-icons/ci';
import { BsInstagram } from 'react-icons/bs';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { FiYoutubep } from 'react-icons/fi';
import instagram from '../components/asset/instagram.png'
import tiktok from '../components/asset/tiktok.png'
import youtube from '../components/asset/youtube1.png'
import { IoIosSearch } from 'react-icons/io';
import { LuUpload } from 'react-icons/lu';
const ValueMatch = () => {
    const platforms = [
        { id: 1, label: "Instagram", Activeicon: instagram, icon: <FaInstagram /> },
        {
            id: 2, label: "YouTube", Activeicon: youtube, icon: <FaYoutube />
        },
        { id: 3, label: "TikTok", Activeicon: tiktok, icon: <FaTiktok /> },

    ];
    const [jsonResult, setJsonResult] = useState([]);
    const [csvData, setCsvData] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [platformDropdown, setPlatformDropdown] = useState(false);
    const [platform, setPlatform] = useState(platforms[0]);

    const handlePlatform = (platform) => {
        setPlatform(platform)
    }

    const handleCSVFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setCsvData(e.target.result);
                Papa.parse(e.target.result, {
                    header: true, // Treat the first row as headers
                    dynamicTyping: true, // Automatically detect data types
                    skipEmptyLines: true, // Skip empty lines
                    complete: (result) => {
                        setJsonResult(result.data);
                    },
                    error: (error) => {
                        console.error('Error converting CSV to JSON: ', error.message);
                    },
                });
            };

            reader.readAsText(file);
        } else {
            // Handle the case where no file was selected
            setCsvData('');
            setJsonResult([]);
        }
    }

    const handleSearchValue = (e) => {
        setSearchValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Your submit logic here
        console.log('Submit clicked with search value:', searchValue);
    };

    return (
        <>
            <div className='mt-2 w-full flex flex-col min-h-screen bg-[#FAFAFA] items-center '>
                <div className="w-[95%] flex flex-col items-center justify-center rounded-lg py-9">
                    <p className='text-3xl mb-1 font-[600] '>Influencer-Brand Value Match
                        <span style={{
                            backgroundImage: 'linear-gradient(to right, #7279FD, #7279FD, #BF00DB)',
                            WebkitBackgroundClip: 'text',
                            backgroundClip: 'text',
                            color: 'transparent',
                            fontWeight: '600',
                        }} > Authentic connections, trust, and conversions.</span> </p>


                    <div className='w-[90%] justify-center flex flex-col my-4 m-auto mt-10'>
                        <div className="flex  overflow-x-auto  ">
                            {platforms.map((socialplatform) => (
                                <button
                                    key={socialplatform.id}
                                    className={`flex-shrink-0 shadow-lg inline-flex justify-center font items-center h-14 w-[160px] py-3 px-4 text-base font-medium text-center
      ${platform.id + 1 === socialplatform.id && 'rounded-bl-[8px]'}
      ${socialplatform.id === 1 && 'rounded-tl-[8px]'}
      ${socialplatform.id === 3 && 'rounded-tr-[8px]'}
      ${platform.id === socialplatform.id ? "bg-white slide-animation" : "bg-black text-white"}
      ${platform.id - 1 === socialplatform.id && 'rounded-br-[8px]'}
      focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600 relative`}
                                    onClick={() => handlePlatform(socialplatform)}
                                    type="button"
                                    style={{
                                        transition: 'background-color 0.3s, color 0.3s, border-radius 0.3s'
                                    }}
                                >
                                    <div className="flex gap-1 items-center justify-center">
                                        {/* Use appropriate icon based on the selected platform */}
                                        {socialplatform.id === platform.id ?
                                            <img src={socialplatform.Activeicon} className="w-[20px]" /> : socialplatform.icon}
                                        {socialplatform.label}
                                    </div>
                                </button>
                            ))}

                        </div>
                        <form onSubmit={handleSubmit} className='shadow-md flex flex-col rounded-b-lg rounded-tr-lg  w-full bg-white'>

                            <div className="relative py-6 px-6 ">

                                <div className="absolute z-[20] w-[20px] left-[50px] top-[43%]"> <IoIosSearch /></div>
                                <input
                                    onChange={handleSearchValue}
                                    type="search"
                                    className={`p-3 pl-12 w-full border-[0.6px] rounded-[4px] broder-[#D9D9D9] h-14 `}
                                    placeholder='Check value 🤷'
                                    required
                                    autoComplete="false"
                                    value={searchValue} // Render value without "@" if isProfile is true
                                />

                            </div>
                            <div className='ml-6 flex justify-between'>

                                <div className='w-auto'>
                                    <label htmlFor="csvFileInput" className='w-auto rounded-md px-3  bg-white py-3 flex items-center gap-1 text-black border-[0.6px] border-[#D9D9D9]   text-sm whitespace-nowrap cursor-pointer' >
                                        <LuUpload className='text-md' />
                                        Upload CSV
                                    </label>
                                    <input type="file" id="csvFileInput" accept=".csv" style={{ display: 'none' }} onChange={handleCSVFileChange} />
                                </div>
                                <div className='w-full flex justify-end'>
                                    <button
                                        onClick={handleSubmit}
                                        type='submit'
                                        className='rounded-[4px] mb-6 mx-6  bg-[#686FFD] text-white py-3 px-4'
                                    > Check Value
                                    </button>
                                </div>
                            </div>
                            {/* <div
                                onMouseOver={() => setPlatformDropdown(true)} onMouseLeave={() => setPlatformDropdown(false)}
                                className={`relative 
                                
                                9-l-xl min-w-[150px] cursor-pointer bg-[#323237] text-white pl-4 pr-2 flex items-center shadow-lg`}>
                                {
                                    platform === 'instagram' ? (
                                        <>
                                            <BsInstagram className='text-xl mr-2 ' /> Instagram <AiOutlineCaretDown className="ml-3" />
                                        </>
                                    ) : platform === 'youtube' ? (
                                        <>
                                            <FiYoutube className='text-2xl mr-2' /> Youtube <AiOutlineCaretDown className="ml-3" />
                                        </>
                                    ) : platform === 'tiktok' ? (
                                        <>
                                            <FaTiktok className='text-xl mr-2' /> TikTok <AiOutlineCaretDown className="ml-3" />
                                        </>
                                    ) : null
                                }
                              
                                {
                                    platformDropdown && <>
                                        <div className="absolute z-40 top-12 left-0 w-full rounded-xl overflow-hidden shadow-xl">
                                            <div onClick={() => handlePlatform('youtube')} className={`cursor-pointer ${platform == 'youtube' ? 'bg-[#6d5eac]' : 'bg-[#323237]'} hover:bg-[#6d5eac] text-white pl-4 pr-2 py-3 flex items-center shadow-lg`}>
                                                <FiYoutube className='text-2xl mr-2' /> Youtube
                                            </div>
                                            <div onClick={() => handlePlatform('instagram')} className={`cursor-pointer ${platform == 'instagram' ? 'bg-[#6d5eac]' : 'bg-[#323237]'} hover:bg-[#6d5eac] text-white pl-4 pr-2 py-3 flex items-center shadow-lg`}>
                                                <BsInstagram className='text-xl mr-2' /> Instagram
                                            </div>
                                            <div onClick={() => handlePlatform('tiktok')} className={`cursor-pointer ${platform == 'tiktok' ? 'bg-[#6d5eac]' : 'bg-[#323237]'} hover:bg-[#6d5eac] text-white pl-4 pr-2 py-3 flex items-center shadow-lg`}>
                                                <FaTiktok className='text-xl mr-2' /> Tiktok
                                            </div>
                                        </div>
                                    </>
                                }
                            </div> */}
                            {/* 
                            <input
                                onChange={handleSearchValue}
                                className='block p-3 w-full z-20 text-base text-black shadow-lg bg-gray-50 focus:ring-[#6d5eac] focus:border-[#6d5eac] dark:bg-gray-700 dark:border-l-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500'
                                type='search'
                                placeholder='Check value 🤷'
                                value={searchValue}
                            />

                            <button
                                onClick={handleSubmit}
                                type='submit'
                                className='bg-[#323237] shadow-lg text-white cursor-pointer rounded-r-xl px-2 flex items-center gap-2 text-sm whitespace-nowrap'
                            >
                                <CiSearch className='text-2xl' /> Check Value
                            </button> */}
                        </form>

                        {/* <div className='rounded-xl shadow-lg text-white flex items-center box-border ml-2'>
                            <label htmlFor="csvFileInput" className='w-full rounded-xl px-3 py-3 flex items-center gap-1 text-white text-sm whitespace-nowrap cursor-pointer' style={{ backgroundColor: "#6d5eac" }}>
                                <FcExport className="text-3xl" />
                                Upload CSV
                            </label>
                            <input type="file" id="csvFileInput" accept=".csv" style={{ display: 'none' }} onChange={handleCSVFileChange} />
                        </div> */}
                    </div>

                </div>

                <div className="my-10 relative w-[95%] flex gap-5">

                    {/* <div className='sticky top-4 w-[300px] h-[300px] flex justify-center items-center bg-[#323237] rounded-2xl shadow-lg hover:shadow-2xl'>
                        <label htmlFor="csvFileInput" className='rounded-lg flex flex-col items-center gap-2 text-white text-xl cursor-pointer' style={{ backgroundColor: "#323237" }}>
                            <FcExport className="text-6xl" />
                            Upload CSV
                        </label>
                        <input type="file" id="csvFileInput" accept=".csv" style={{ display: 'none' }} onChange={handleCSVFileChange} />
                    </div> */}

                    {
                        jsonResult.length > 0 && <>
                            <div className="container mx-auto">
                                <table className="min-w-full w-full">
                                    <thead>
                                        <tr className='sticky top-0 z-30'>
                                            <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                                Username
                                            </th>
                                            <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                                Followers
                                            </th>
                                            <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                                Eng. Rate
                                            </th>
                                            <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                                Average Views
                                            </th>
                                            <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jsonResult.length === 0 ? (
                                            <tr>
                                                <td colSpan="5" className="text-center py-10 text-gray-500">
                                                    <h1 className='text-xl'>No data found</h1>
                                                </td>
                                            </tr>
                                        ) : (
                                            jsonResult.map((item, index) => (
                                                <tr key={index} className='border even:bg-blue-50'>
                                                    <td className="py-5 whitespace-no-wrap border-b border-gray-300">
                                                        <Avatar
                                                            img="/avatar.png"
                                                            rounded
                                                            className='z-0'
                                                        >
                                                            <div className="font-medium dark:text-white">
                                                                <div>
                                                                    {item.username}
                                                                </div>
                                                                <div className="text-sm text-gray-500 dark:text-gray-400">
                                                                    Joined in August 2014
                                                                </div>
                                                            </div>
                                                        </Avatar>
                                                    </td>
                                                    <td className="px-6 py-5 whitespace-no-wrap border-b border-gray-300">
                                                        {item.follower}
                                                    </td>
                                                    <td className="px-6 py-5 whitespace-no-wrap border-b border-gray-300">
                                                        {item.eng_rate}
                                                    </td>
                                                    <td className="px-6 py-5 whitespace-no-wrap border-b border-gray-300">
                                                        {item.avg_view}
                                                    </td>
                                                    <td className="py-5 whitespace-no-wrap border-b border-gray-300">
                                                        {item.status == "pending" ? <Button color="gray">
                                                            <Spinner
                                                                aria-label="Alternate spinner button example"
                                                                size="sm"
                                                            />
                                                            <span className="pl-2">
                                                                Loading...
                                                            </span>
                                                        </Button> : <Button>
                                                            <MdDone />
                                                            <span className="pl-3">
                                                                {item.status}
                                                            </span>
                                                        </Button>}
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }

                </div>
            </div>
        </>
    )
}

export default ValueMatch
