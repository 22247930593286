'use client';

import FormData from "form-data";
import React, { useCallback, useEffect, useRef } from 'react'
import { FcExport } from 'react-icons/fc'
import { useState } from 'react'
import constants from "../constants/constant";
import Papa from 'papaparse';
import { FaInstagram, FaTiktok, FaVideoSlash, FaYoutube } from 'react-icons/fa';
import { CiSearch } from 'react-icons/ci';
import { AiOutlineCaretDown, AiOutlineDownload } from 'react-icons/ai';
import { BsInstagram } from "react-icons/bs";
import { FiYoutube } from "react-icons/fi";
import ToggleSearchedView from "./ToggleSearchedView";
import LoadingSpinner from "./ResultPage/LoadingSpinner";
import { MdHistory } from "react-icons/md";
import SearchedHistory from "./SearchedHistory";
import axiosInstance from "../interseptor";
import { HiBadgeCheck } from "react-icons/hi";
import { Badge } from "flowbite-react";
import { Link } from "react-router-dom";
import { RiCloseFill } from "react-icons/ri";
import instagram from '../components/asset/instagram.png'
import { Tooltip } from '@mui/material';
import tiktok from '../components/asset/tiktok.png'
import youtube from '../components/asset/youtube1.png'
import { IoIosSearch } from 'react-icons/io';
import { LuUpload } from 'react-icons/lu';
import SearchComponent from "../components/Search/SearchComponent";
import EditReportNameModal from "./ReportPage/EditReportNameModal";
const CSVFILE_URL = `https://viralpitch.co/d2/dashboard/similar_profile_sample.csv`

const EngRateCal = () => {
    const platforms = [
        { id: 1, label: "Instagram", Activeicon: instagram, icon: <FaInstagram /> },
        {
            id: 2, label: "YouTube", Activeicon: youtube, icon: <FaYoutube />
        },
        { id: 3, label: "TikTok", Activeicon: tiktok, icon: <FaTiktok /> },

    ];
    const [jsonResult, setJsonResult] = useState([]);
    const [csvData, setCsvData] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [userData, setUserData] = useState([]);
    const [realUserData, setRealUserData] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [platform, setPlatform] = useState(platforms[0]);
    const [platformDropdown, setPlatformDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reportId, setReportId] = useState();
    const [shouldContinueInterval, setShouldContinueInterval] = useState(true);
    const [showDownload, setShowDownload] = useState(false)
    const [searchUsername, setSearchUsername] = useState([])
    const [showSuggestion, setShowSuggestion] = useState(false)
    const [loadingTopics, setLoadingTopics] = useState(false);
    const [searchInput, setsearchInput] = useState('');
    const [username, setAllusername] = useState([])
    // for showing platform - true -false - while searching
    const [showSearchSuggestion, setShowSearchSuggestion] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState(constants.platforms[0]);
    const [searches, setSearches] = useState([]);
    const [searchName, setSearchName] = useState([]);
    const [reportList, setReportList] = useState([])
    const [searchTopic, setSearchTopic] = useState([]);
    const inputRef = useRef(null)

    // const [shouldContinueInterval, setShouldContinueInterval] = useState(true);

    // const [searchHistory, setSearchHistory] = useState(() => {
    //     // Load search history from local storage on component mount
    //     const storedHistory = localStorage.getItem('searchHistory');
    //     return storedHistory ? JSON.parse(storedHistory) : [];
    // });

    const downloadCsv = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click()
        aTag.remove();
    }

    const handlePlatform = (platform) => {
        setPlatform(platform)
    }

    const handleCSVFileChange = (event) => {
        setSearchValue("")
        setSearchResult([])
        setRealUserData([]);
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setCsvData(e.target.result);
                Papa.parse(e.target.result, {
                    header: true, // Treat the first row as headers
                    dynamicTyping: true, // Automatically detect data types
                    skipEmptyLines: true, // Skip empty lines
                    complete: (result) => {
                        setJsonResult(result.data);
                    },
                    error: (error) => {
                        console.error('Error converting CSV to JSON: ', error.message);
                    },
                });
            };

            reader.readAsText(file);
        } else {
            // Handle the case where no file was selected
            setCsvData('');
            setJsonResult([]);
        }
    }

    const handleSearchValue = (e) => {
        if (e.target.value.length === 0) {
            setShowSuggestion(false)
        }
        else {
            setShowSuggestion(true)
        }
        setSearchValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearchResult(searchValue.split(", "));
        setRealUserData([])
        setShowSuggestion(false)
        // Save the search to history
        // const updatedHistory = [...searchHistory, searchValue];
        // setSearchHistory(updatedHistory);

        // Save the updated history to local storage
        // localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
    };


    useEffect(() => {
        setLoading(true);

        const fetchInitialData = async () => {
            setShowDownload(false)
            try {
                var formdata = new FormData();
                formdata.append("logintype", "calculate-engagement");
                formdata.append("device_id", "seacrh");
                formdata.append("platform", platform.label);
                formdata.append("csvfile", new Blob([csvData], { type: 'text/csv' }), "username.csv");
                formdata.append("upload", "");
                formdata.append("uploadfile", searchValue === "" ? "1" : "0");
                formdata.append("username", username);

                const response = await axiosInstance.post("livesearch/calculate-engagement", formdata)
                console.log('response first', response?.data)

                setUserData(response?.data);
                setLoading(false);
                if (response?.data.list.length > 0) {
                    setReportId(response?.data.report_id)
                    setShowDownload(true)
                }

            } catch (error) {
                console.log('error', error);
                setLoading(false);
            }
        }

        fetchInitialData()


    }, [csvData, searchResult])

    // console.log("checking data from API", userData)


    const convertToCsv = () => {
        const csvHeaders = ["Username", "Followers", "Eng. Rate", "Avg. Likes", "Avg. Comments", "Avg. Plays", "Media Count", "Phone Number", "Email ID", "Status"];

        const csvData = [csvHeaders];

        userDataFn()?.list.forEach((item) => {
            const row = [
                item.username,
                item.follower,
                item.engagement,
                item.avg_like_count,
                item.avg_comment,
                item.avg_play_count,
                item.media_count,
                item.phone,
                item.email,
                item.scrap_status,
            ];
            csvData.push(row);
        });

        return Papa.unparse(csvData);
    };

    const handleDownload = () => {
        const csvData = convertToCsv();

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'userData.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
    };

    console.log('reportId 11', reportId)

    const fetchData = async (val) => {
        console.log("help")
        try {
            let formdata = new FormData();
            formdata.append("logintype", "get_eng_report");
            formdata.append("device_id", "seacrh");
            formdata.append("report_id", val || reportId);

            // Only fetch if shouldContinueInterval is true
            // if (shouldContinueInterval && userData && userData?.list?.length > 0 && userData?.list !== ' ') {
            const response = await axiosInstance.post("livesearch/get_eng_report", formdata);

            console.log('data', response.data)

            setRealUserData(response.data);

            // Additional logic or processing with the fetched data can be added here

            setLoading(false);

            // Check if there are no items with scrap_status === 0
            const noItemsWithScrapStatusZero = response?.data?.list.every(item => item?.scrap_status !== "0");

            // console.log("scrap status =", noItemsWithScrapStatusZero);

            // If no items have scrap_status === 0, stop the interval
            if (noItemsWithScrapStatusZero) {
                setShouldContinueInterval(false);
            }
            // } else {
            // Set loading to false when userData.length is 0 or is ' ' or undefined
            setLoading(false);
            setShowDownload(true)
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {

        // Set up an interval to fetch data every 5 seconds
        const newIntervalId = setInterval(() => {

            // Fetch data only when shouldContinueInterval is true
            if (shouldContinueInterval && userData && userData?.list?.length > 0 && userData?.list !== ' ') {
                // console.log({ TEST: "ADFASF", userData })
                fetchData();
            }
        }, 3000);

        // Cleanup the interval when the component unmounts or shouldContinueInterval is false
        return () => {
            clearInterval(newIntervalId);
            if (!shouldContinueInterval) {
                setShouldContinueInterval(true); // Reset the state for future use
            }
        };

    }, [userData, shouldContinueInterval]);  // Dependencies array should include userData and shouldContinueInterval

    useEffect(() => {
        const fetchData = async (value) => {
            setLoadingTopics(true);

            try {
                const formdata = new FormData();
                formdata.append("logintype", "searchtopic");
                formdata.append("device_id", "seacrh");
                formdata.append("platform", selectedPlatform.label);
                formdata.append("filter", "lookalike");
                formdata.append("search_string", value.substring(1)); // Remove '@' from the username


                const response = await axiosInstance.post("livesearch/searchtopic", formdata);

                setSearchTopic(response?.data?.response?.data);
                setSearchUsername([])
                setLoadingTopics(false);

                if (searchInput.length === 0) {
                    setSearchTopic([]);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };


        if (searchValue.length > 1) {
            fetchData(searchValue);
        }

    }, [searchValue, selectedPlatform.label]);
    const userDataFn = () => {
        return realUserData?.list?.length > 0 ? realUserData : userData
    }
    console.log(username)
    function reportIdNew(val) {
        console.log({ val })
        fetchData(val)
        setShowDownload(true)
    }

    useEffect(() => {
        fetchSimilarUsername(searchValue)
        getReportList()
    }, [searchValue])

    const fetchSimilarUsername = async (value) => {
        try {

            const formdata = new FormData();
            formdata.append("filter", "lookalike");
            formdata.append("logintype", "getfilters");
            formdata.append("device_id", "search");
            formdata.append("platform", platform.label.toLowerCase());
            formdata.append("search_string", value || '');

            const response = await axiosInstance.post("livesearch/getfilters", formdata);

            console.log("result of similar user", response?.data)
            if (response?.data?.status === 1) {
                setSearchUsername(response?.data?.response?.data?.data);
                console.log('lookalike data', response.data.response.data);
            } else {
                // setError(response.msg);
                console.error('Error:', response?.data?.msg);
            }
        } catch (error) {
            // setError('An error occurred while fetching data.');
            console.error('Error:', error);
        } finally {
            // setLoading(false);
        }
    };

    // const handleShowSuggest = () => {
    //     setShowSuggestion(true)
    // }

    const removeFromSearch = (index) => {
        const updatedSearches = [...username];
        updatedSearches.splice(index, 1);
        setAllusername(updatedSearches);
        const updatedSearchNames = [...username];
        updatedSearchNames.splice(index, 1);
        setAllusername(updatedSearchNames);
    };
    const filteredCatagogary = searchTopic?.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()));


    function usernamhHandler(val) {
        console.log(val)
        const allusernmae = [...username]
        const findUsername = allusernmae.find((name) => {
            return name === val.username
        })

        if (findUsername === undefined) {
            setAllusername(prev => [...prev, val.username])

        }
        console.log(findUsername)
    }

    const getReportList = async () => {
        const GET_REPORT_LIST_URL = "/livesearch/reportlist";

        try {
            let data = new FormData();
            data.append("logintype", "reportlist");
            data.append("device_id", "seacrh");
            data.append("report_type", 1);

            const response = await axiosInstance.post(GET_REPORT_LIST_URL, data);
            console.log({ response })
            if (response?.data?.status === 0) {
                setLoading(false)
                return
            }

            if (response && response?.status === 200) {
                if (response && response?.data && response?.data?.status === 201) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                } else if (
                    response &&
                    response?.data &&
                    response?.data?.status === 2
                ) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                } else if (
                    response &&
                    response?.data &&
                    response?.data?.status === 3
                ) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                }

                setReportList(response.data.list.report_list.slice(0, 5));
                setLoading(false)
                console.log("report response", response.data);
                setLoading(false);
                return response?.data.list;
            }
        } catch (error) { }
    };


    const datefunc = (datestring) => {
        const createdOn = new Date(datestring);
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC", // Assuming your date string is in UTC
        };
        const formattedDate = createdOn.toLocaleString("en-US", options);
        return formattedDate;
    };

    return (

        <div className=' relative bg-[#FAFAFA] h-screen mt-2 w-full flex flex-col items-center'>
            <div className="w-[95%] z-40 flex flex-col items-center justify-center rounded-lg  py-9">

                <p className='text-3xl mb-1 font-[600] '> Engagement Rate Calculator
                    <span style={{
                        backgroundImage: 'linear-gradient(to right, #7279FD, #7279FD, #BF00DB)',
                        WebkitBackgroundClip: 'text',
                        backgroundClip: 'text',
                        color: 'transparent',
                        fontWeight: '600',
                    }} >  Boost Your Influence with Our Engagement Rate Calculator.</span> </p>

                {/* <SearchComponent/> */}
                <div className='w-[900px]  xl:w-[95%] justify-center flex   flex-col my-4 m-auto mt-10'>

                    <div className="flex  w-full overflow-x-auto  ">
                        {platforms.map((socialplatform) => (
                            <button
                                key={socialplatform.id}
                                className={`flex-shrink-0  inline-flex justify-center font items-center h-14 w-[160px] py-3 px-4 text-base font-medium text-center
      ${platform.id + 1 === socialplatform.id && 'rounded-bl-[8px]'}
      ${socialplatform.id === 1 && 'rounded-tl-[8px]'}
      ${socialplatform.id === 3 && 'rounded-tr-[8px]'}
      ${platform.id === socialplatform.id ? "bg-white slide-animation" : "bg-black text-white"}
      ${platform.id - 1 === socialplatform.id && 'rounded-br-[8px]'}
      focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600 relative`}
                                onClick={() => handlePlatform(socialplatform)}
                                type="button"
                                style={{
                                    transition: 'background-color 0.3s, color 0.3s, border-radius 0.3s'
                                }}
                            >
                                <div className="flex gap-1 items-center justify-center">
                                    {/* Use appropriate icon based on the selected platform */}
                                    {socialplatform.id === platform.id ?
                                        <img src={socialplatform.Activeicon} className="w-[20px]" /> : socialplatform.icon}
                                    {socialplatform.label}
                                </div>
                            </button>
                        ))}

                    </div>
                    <div className="shadow-md rounded-lg">
                        <div className="relative py-6 px-6 rounded-tr-[8px] w-full bg-white ">

                            <span className="w-full pl-12 flex overflow-auto min-w-[300px] items-center border bg-gray-50 border-gray-300 p-0 gap-[5px]">
                                <div className="max-w-auto flex overflow-auto">
                                    {username && username?.map((name, index) => (
                                        <button
                                            key={index}
                                            className="my-1 mx-1 px-2 py-1 flex whitespace-nowrap items-center capitalize bg-blue-100 text-blue-800 rounded-lg text-sm"
                                            onClick={() => removeFromSearch(index)}
                                        >
                                            {name}
                                            <RiCloseFill />
                                        </button>
                                    ))}
                                </div>
                                <div className="absolute z-[24] w-[20px] left-[50px] top-[43%]"> <IoIosSearch /></div>

                                <input
                                    onChange={handleSearchValue}
                                    // onMouseEnter={() => setShowPlatform(false)}

                                    value={searchValue}
                                    type="search"
                                    className={`p-3 ${searchValue.length > 0 ? "w-fit min-w-[50px]" : "w-full"} pl-0 border-none z-20 text-base text-black bg-gray-50 border-0 focus:ring-transparent`}
                                    placeholder={`${searchValue.length > 0 ? "Add More" : "Enter username to calculate engagement rate"}`}
                                    required
                                    autoComplete="false"
                                />
                                <div
                                    className={`absolute left-0 bg-white z-50 h-[300px] w-[50%] overflow-auto  ${loadingTopics || !showSuggestion ? 'bottom-0 top-[100%]' : "bottom-0 top-[100%]"} rounded-lg  ${showSuggestion ? "" : "hidden"}`}

                                >
                                    {
                                        loadingTopics || !showSuggestion ? (
                                            // Render loading indicator while data is being fetched
                                            <div className="text-lg font-semibold pb-5 p-5">Loading...</div>
                                        ) : searchUsername?.length > 0 ? (
                                            <div className="pl-5 pt-5 w-[50%]">
                                                <div className="text-gray-500 text-lg font-normal">Similer Username</div>
                                                <div className="flex flex-wrap">
                                                    {searchUsername
                                                        ?.filter((category) => category?.username) // Filter out items without a username
                                                        .slice(0, 20) // Limit the display to the first 20 items
                                                        .map((category) => (

                                                            <button key={category?.user_id} onClick={() => { usernamhHandler(category) }}>
                                                                <Badge className="my-2 rounded-full mr-2 p-1 pr-3 cursor-pointer bg-indigo-100 text-indigo capitalize">
                                                                    <div className="flex items-center gap-1">
                                                                        {
                                                                            category?.picture &&
                                                                            <div className="w-8 h-8 rounded-full overflow-hidden">
                                                                                <img className="w-full h-full object-cover" src={category?.picture} alt="img" />
                                                                            </div>
                                                                        }

                                                                        <div className="flex items-center gap-1">
                                                                            <div className="flex flex-col justify-start items-start">
                                                                                <div className="flex gap-1">
                                                                                    <p>
                                                                                        {category?.username}
                                                                                    </p>
                                                                                    <div>
                                                                                        {
                                                                                            category?.is_verified && <HiBadgeCheck className='text-primary-500' size={16} />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <p className="text-gray-600 text-xs font-thin">
                                                                                    {Intl.NumberFormat("en-US", {
                                                                                        notation: "compact",
                                                                                        maximumFractionDigits: 1,
                                                                                    }).format(category?.followers)}
                                                                                    &nbsp; Followers
                                                                                </p>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Badge>
                                                            </button>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                            : (searchValue !== "" && searchValue.length > 1) &&
                                            <>
                                                <button >
                                                    <Badge className="my-2 rounded-full mr-2 p-1 pr-3 cursor-pointer bg-indigo-100 text-indigo capitalize">
                                                        <div className="flex items-center gap-1">
                                                            {
                                                                <div className="w-8 h-8 rounded-full overflow-hidden">
                                                                    <img className="w-full h-full object-cover" src={"/avatar.png"} alt="img" />
                                                                </div>
                                                            }

                                                            <div className="flex items-center gap-1">
                                                                <div className="flex flex-col justify-start border items-start">
                                                                    <div className="flex gap-1">
                                                                        <p onClick={() => { setAllusername(prev => [...prev, searchValue]) }}>
                                                                            {searchValue}
                                                                        </p>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Badge>
                                                </button>
                                            </>
                                    }




                                </div>
                            </span>

                        </div>
                        <div className='pl-6 rounded-[8px]  flex justify-between bg-white'>
                            <div>
                                <SearchedHistory sendReportId={reportIdNew} reportType={1} />
                            </div>
                            <div className='w-auto'>
                                <label htmlFor="csvFileInput" className='w-auto rounded-md px-3  bg-white py-3 flex items-center gap-1 text-black border-[0.6px] border-[#D9D9D9]   text-sm whitespace-nowrap cursor-pointer' >
                                    <LuUpload className='text-md' />
                                    Upload CSV
                                </label>
                                <input type="file" id="csvFileInput" accept=".csv" style={{ display: 'none' }} onChange={handleCSVFileChange} />
                            </div>
                            <div onClick={() => { downloadCsv(CSVFILE_URL) }} >
                                <div className="w-auto rounded-md px-3 ml-2 bg-white py-3 flex items-center gap-1 text-black border-[0.6px] border-[#D9D9D9]   text-sm whitespace-nowrap cursor-pointer">
                                    <AiOutlineDownload className="text-md" />
                                    Sample CSV
                                </div>

                            </div>

                            <div className='w-full items-center  flex justify-end'>

                                <button
                                    onClick={handleSubmit}
                                    type='submit'
                                    className='rounded-[4px] mb-6 mx-6  bg-[#686FFD] text-white py-3 px-4'
                                > Check Value
                                </button>

                            </div>
                        </div>
                    </div>

                    <div className='flex w-full'>
                        {/* <div
                            onMouseOver={() => setPlatformDropdown(true)} onMouseLeave={() => setPlatformDropdown(false)}
                            className={`relative rounded-l-xl min-w-[150px] cursor-pointer bg-[#323237] text-white pl-4 pr-2 flex items-center shadow-lg`}>
                            {
                                platform === 'instagram' ? (
                                    <>
                                        <BsInstagram className='text-xl mr-2 ' /> Instagram <AiOutlineCaretDown className="ml-3" />
                                    </>
                                ) : platform === 'youtube' ? (
                                    <>
                                        <FiYoutube className='text-2xl mr-2' /> Youtube <AiOutlineCaretDown className="ml-3" />
                                    </>
                                ) : platform === 'tiktok' ? (
                                    <>
                                        <FaTiktok className='text-xl mr-2' /> TikTok <AiOutlineCaretDown className="ml-3" />
                                    </>
                                ) : null
                            }
                           
                            {
                                platformDropdown && <>
                                    <div className="absolute z-40 top-12 left-0 w-full rounded-xl overflow-hidden shadow-xl">
                                        <div onClick={() => handlePlatform('youtube')} className={`cursor-pointer ${platform == 'youtube' ? 'bg-[#6d5eac]' : 'bg-[#323237]'} hover:bg-[#6d5eac] text-white pl-4 pr-2 py-3 flex items-center shadow-lg`}>
                                            <FiYoutube className='text-2xl mr-2' /> Youtube
                                        </div>
                                        <div onClick={() => handlePlatform('instagram')} className={`cursor-pointer ${platform == 'instagram' ? 'bg-[#6d5eac]' : 'bg-[#323237]'} hover:bg-[#6d5eac] text-white pl-4 pr-2 py-3 flex items-center shadow-lg`}>
                                            <BsInstagram className='text-xl mr-2' /> Instagram
                                        </div>
                                        <div onClick={() => handlePlatform('tiktok')} className={`cursor-pointer ${platform == 'tiktok' ? 'bg-[#6d5eac]' : 'bg-[#323237]'} hover:bg-[#6d5eac] text-white pl-4 pr-2 py-3 flex items-center shadow-lg`}>
                                            <FaTiktok className='text-xl mr-2' /> Tiktok
                                        </div>
                                    </div>
                                </>
                            }
                        </div> */}


                    </div>




                    {/* <div className="flex gap-1 items-center whitespace-nowrap">
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                        <label for="vehicle1"> Without Reel Views</label>
                    </div> */}
                </div>

            </div >







            {
                loading ? <>
                    <div className="mb-10 mt-5 relative w-[95%] flex gap-5">
                        <div className="w-full flex justify-center my-5">
                            <button className="bg-[#201E20] duration-200 transition-all hover:opacity-90 text-slate-50 px-4 py-2 rounded-lg">
                                <LoadingSpinner />  Loading
                            </button>
                        </div>
                    </div>
                </>
                    :
                    (userData?.list?.length > 0 || (Array.isArray(userDataFn()?.list) && userDataFn()?.list.length > 0)) > 0 && <>
                        <table className="min-w-[90%] w-[95%] mb-10 mt-0 relative container mx-auto overflow-x-auto">
                            <thead className='sticky top-0 z-30 bg-[#EEF5FF] h-14 text-[#828282]'>
                                <tr className='h-14 '>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Username
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Followers
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Eng. Rate
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Avg. Likes
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Avg. Comments
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Avg. Plays
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Media Count
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Phone Number
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Email ID
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        Status
                                    </th>
                                    <th className="px-6 py-4 font-medium text-left text-base leading-4  tracking-wider">
                                        {
                                            showDownload && (
                                                < button
                                                    className=" flex gap-1 items-center rounded-[4px]  text-black"
                                                    onClick={handleDownload}>
                                                    <Tooltip title="Download" placement="right-start" >
                                                        <AiOutlineDownload className="text-lg" />
                                                    </Tooltip>
                                                </button>

                                            )
                                        }
                                    </th>
                                </tr>
                            </thead>

                            <tbody>

                                {userData?.list?.length === 0 && searchValue.length > 0 ? (
                                    <tr>
                                        <td colSpan="10" className="text-center py-10 text-gray-500">
                                            <h1 className='text-xl'>No data found</h1>
                                        </td>
                                    </tr>
                                ) : (

                                    userDataFn()?.list.map((item, index) => (
                                        <tr key={index} className='border bg-white'>
                                            <td className="py-2 flex items-center gap-2 pl-2 whitespace-no-wrap">

                                                <div className="min-w-[55px] w-[55px] min-h-[55px] h-[55px] rounded-full overflow-hidden">
                                                    <img className="object-cover h-[100%] w-[100%]" src={item?.img || "/avatar.png"} alt="img" />
                                                </div>

                                                <div className="font-medium text-sm dark:text-white">
                                                    <div>
                                                        {item?.username}
                                                    </div>
                                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                                        {item?.name}
                                                    </div>
                                                </div>

                                            </td>

                                            <td className="px-6 py-2 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.follower))}
                                            </td>

                                            <td className="px-6 py-2 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {(item?.engagement.toString()).length <= 4 ? `${(item?.engagement.toString())}%` : `${(item?.engagement.toString()).slice(0, 4)}%`}
                                            </td>

                                            <td className="px-6 py-2 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.avg_like_count))}
                                            </td>

                                            <td className="px-6 py-2 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.avg_comment))}
                                            </td>

                                            <td className="px-6 py-2 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.avg_play_count))}
                                            </td>

                                            <td className="px-6 py-2 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.media_count))}
                                            </td>

                                            <td className="px-6 py-2 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {item?.phone && `${item?.phone.charAt(0).toUpperCase()}${item?.phone.slice(1)}`}
                                            </td>

                                            <td className="px-6 py-2 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {item?.email && `${item?.email.charAt(0).toUpperCase()}${item?.email.slice(1)}`}
                                            </td>

                                            <td className="px-6 py-2 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {item?.scrap_status == 0 && <button className="w-[100px] flex gap-1 justify-center items-center bg-[#f7cf5f] duration-200 transition-all hover:opacity-90 text-black px-2 py-2 rounded-lg">
                                                    <LoadingSpinner className="text-xs" />  Loading
                                                </button>}
                                                {item?.scrap_status == 1 && <button className="rounded-[40px]  py-1 px-3 text-black font-[400] text-sm  bg-[#D5F0C1] rounded-[40px]  py-1 px-3 text-black font-[400] text-sm  ">
                                                    Valid User
                                                </button>}
                                                {item?.scrap_status == 2 && <button className="rounded-[40px]  py-1 px-3 text-black font-[400] text-sm  bg-[#FFC2C2] rounded-[40px]  py-1 px-3 text-black font-[400] text-sm ">
                                                    Invalid User
                                                </button>}
                                            </td>
                                            <td></td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </>
            }
            <p className="text-left items-start font-semibold w-[90%] mb-5">Recent Searches</p>
            <table className="min-w-[90%]  pl-14   relative container mx-auto overflow-x-auto">
                <thead className='sticky h-14 bg-[#EEF5FF] text-black top-0 z-30 text-sm'>
                    <tr className=''>
                        <th className="px-4 py-3 font-medium  text-left leading-4  tracking-wider">
                            Platform
                        </th>
                        <th className="px-4 py-3 font-medium  text-left leading-4  tracking-wider">
                            Report Name
                        </th>
                        <th className="px-4 py-3 font-medium  text-left leading-4  tracking-wider">
                            Total Users
                        </th>
                        <th className="px-4 py-3 font-medium  text-left leading-4  tracking-wider">
                            Searched On
                        </th>
                        <th className="px-4 py-3 font-medium  text-left leading-4  tracking-wider">
                            Action
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <div className='w-full border text-center'>{""}</div>
                    {reportList?.length === 0 ? (
                        <tr className=" h-14 ">
                            <td colSpan="5" className="text-center py-10 text-gray-500">
                                <h1 className='text-xl'>No data found</h1>
                            </td>
                        </tr>
                    ) : (
                        reportList.map((item, index) => (
                            <tr key={index} className='  h-14  border '>
                                <td className="py-3 pl-2 whitespace-no-wrap">
                                    {item?.platform}
                                </td>

                                <td className="px-4  py-3 text-sm whitespace-no-wrap border-b border-gray-300">
                                    <div className='flex items-center gap-0.5'>
                                        {item?.report_name || <span>Unknown</span>}
                                        <EditReportNameModal uniqueId={item?.report_id} />
                                    </div>
                                </td>

                                <td className="px-4 py-3 text-sm whitespace-no-wrap border-b border-gray-300">
                                    {Intl.NumberFormat("en-US", {
                                        notation: "compact",
                                        maximumFractionDigits: 1,
                                    }).format(Math.floor(item?.total_users))}
                                </td>

                                <td className="px-4 py-3 text-sm whitespace-no-wrap border-b border-gray-300">
                                    {datefunc(item?.added_on)}
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-no-wrap border-b border-gray-300">
                                    <button onClick={() => reportIdNew(item?.report_id)}
                                        className='flex items-center px-2 py-1.5 rounded-md bg-[#1dbb99] hover:opacity-90 text-sm text-white'>
                                        View Report
                                    </button>
                                </td>

                            </tr>
                        ))
                    )}
                </tbody>
            </table>




        </div >
    )
}

export default EngRateCal
