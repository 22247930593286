import React, { useEffect, useState, useRef } from "react";
import { Button, Label, TextInput, Select } from "flowbite-react";
import { CiSquareRemove } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import * as BSIcon from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import axiosInstance from "../../../interseptor";
import { createPortal } from "react-dom";
import capitalise from "../capitalise";
import { ToastContainer, toast } from "react-toastify";
import ActiveInstagram from '../../asset/instagram.png'
import Activefacebook from '../../asset/facebook.png'
import Activeyoutube1 from '../../asset/youtube 2.png'

const VIEW_LIST_URL = "livesearch/fetch_deliverable";
const EDIT_LIST_URL = "/livesearch/editlist";
const ADD_DELIVERABLE_URL = "/livesearch/add_deliverable";

const EditListDetails = ({ editListOpen, setEditListOpen, listId, planId, refreshPlan, listDetails }) => {
  // const [listDetails, setListDetails] = useState([]);

  const selectedList = listDetails.filter((listDetails) => {
    return listDetails.list_id === listId
  })

  const [deliverables, setDeliverables] = useState([]);
  const [isDeliverableChecked, setIsDeliverableChecked] = useState(false);
  const [platforms, setPlatforms] = useState(["instagram", "youtube", "facebook"]);
  const [rem_id, setRem_id] = useState([])
  const [selectedPlatforms, setSelectedPlatforms] = useState(selectedList[0]);
  const [currentPlatformName, setCurrentPlatformName] = useState("");
  const [deliverableOptions, setDeliverableOptions] = useState([]);
  const [listName] = useState(selectedList[0].list_name);
  const handleSelectChange = (index, deliverable_id, id_, value) => {
    // Update the cat_id of the deliverable at the current index
    const updatedDeliverables = deliverables.map((deliverable, i) =>
      i === index ? { ...deliverable, cat_id: value } : deliverable
    );

    // Disable the selected option
    const updatedOptions = deliverableOptions.map(option =>
      option.id === value ? { ...option, disabled: true } : option
    );

    setDeliverables(updatedDeliverables);
    setDeliverableOptions(updatedOptions);
  };
  console.log({ deliverables })

  const handleInputChange = (index, deliverable_id, id_, value) => {

    let filtered = deliverables.find((deliverable) => {
      return deliverable.id == deliverable_id;
    });

    filtered = { ...filtered, [id_]: value };
    const updated = deliverables?.map((deliverable) => {
      if (deliverable.id == deliverable_id) {
        return filtered;
      } else {
        return deliverable;
      }
    });
    setDeliverables(updated);
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await getDelieveralbleOption(selectedPlatforms);
        if (response.data.list) {
          response.data.list.map(option => {
            deliverables.map((value) => {
              if (value.cat_id == option.id) {
                option.disabled = true
              }
            })
          }
          );
          setDeliverableOptions([...response.data.list]);

        }
        setCurrentPlatformName(selectedPlatforms);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (selectedPlatforms) {
      fetchData();
    }

  }, [selectedPlatforms]);
  const handleDeliverableCheck = () => {
    if (isDeliverableChecked) {
      setIsDeliverableChecked(false);
      setDeliverables(null);
    } else {
      setIsDeliverableChecked(true);
    }
    if (deliverables) {
      setDeliverables([{ id: "", cat_id: "", posts: "", amount: "" }]);
    }
  };
  const handleAddMore = () => {
    const new_del = { id: uuidv4(), cat_id: deliverables[0].cat_id, posts: "", amount: "" };
    if (deliverables?.length == 0) {
      setDeliverables([new_del]);
    } else if (deliverables.length > 0) {
      setDeliverables([...deliverables, new_del]);
    }
  };

  const handleDeliveryDelete = (index_, deliverable_id) => {
    const filtered = deliverables?.filter((item, index) => {
      return index != index_;
    });

    // Disable the selected option
    // const updatedOptions = deliverableOptions.map(option => { { console.log(option,deliverable_id) } }
    // );
    // setDeliverableOptions(updatedOptions)
    setRem_id((prev) => [...prev, deliverable_id])
    const updated = deliverables.filter((deliverable) => {
      // console.log(deliverable.id,deliverable_id)
      return deliverable.id != deliverable_id;
    });

    if (updated?.length == 0) {
      setIsDeliverableChecked(false)
    }
    setDeliverables(updated);
  };

  const getDelieveralbleOption = async (platform) => {
    let config = {
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token_data")).token,
      },
      responsecat_id: "json"
    }
    var formData = new FormData()
    formData.append("logintype", "getdeliverables")
    formData.append("device_id", "seacrh")
    formData.append("platform", platform)
    const response = await axiosInstance.post('livesearch/getdeliverables', formData, config)
    return response
  }

  const handlePlatform = async (platform) => {
    const response = await getDelieveralbleOption(platform)
    setDeliverableOptions([...response.data.list])
    setCurrentPlatformName(platform);
    if (!selectedPlatforms.includes(platform)) {
      setSelectedPlatforms((prev) => prev = [platform]);
    } else {
      const updated = selectedPlatforms.filter((platform_) => {
        return platform_ != platform;
      });
      setSelectedPlatforms(updated);
    }
  };


  async function getListInfo() {
    try {
      let data = new FormData();
      data.append("logintype", "fetch_deliverable");
      data.append("device_id", "serach");
      data.append("list_id", listId);
      data.append("plan_id", planId);

      const response = await axiosInstance.post(VIEW_LIST_URL, data);
      if (response.data.msg) {

        setDeliverables([]);
        setSelectedPlatforms(selectedList[0].list_platform.toLowerCase());
      }
      else {
        setDeliverables(response?.data?.data);
        setSelectedPlatforms((response?.data?.data[0].deliverable.split(' '))[0].toLowerCase());
      }
      setIsDeliverableChecked(() => {
        if (Array.isArray(response?.data?.data) && response?.data?.data.length > 0) {
          return true;
        } else {
          return false;
        }
      });

      // setListName(response?.data?.list?.list_detail.list_name);
      setPlatforms(["instagram", "youtube", "facebook"]);


      // setListDel(response)
      // setListDetails(response.data.list);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {

    getListInfo();
  }, [listId]);

  const updateList = async () => {
   
    const updatedDeliverables = deliverables.map((deliverable) => {
      // Check if the id matches the UUIDv4 pattern
      const isUUIDv4 = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(deliverable.id);

      // If it matches, set id to an empty string, otherwise keep it as is
    
      return {
        ...deliverable,
        id: isUUIDv4 ? "" : deliverable.id,
        amount: deliverable.amount ? deliverable.amount : 0,
        cat_id: deliverable.cat_id || deliverableOptions[0].id
      };
    });

    const del = JSON.stringify(updatedDeliverables)
    try {
      let data = new FormData();
      data.append("logintype", "add_deliverable");
      data.append("device_id", "seacrh");
      data.append("list_id", listId);
      data.append("rem_id", rem_id);
      data.append("data", del);
      data.append("plan_id", planId);
      data.append("link", "325e64b85d4355180fb50af1b239a30c");

      const response = await axiosInstance.post(ADD_DELIVERABLE_URL, data);

      if (response && response?.data?.status_code == 200) {
        if (response?.data?.status == 1) {
          setEditListOpen(false)
          toast.success(response?.data?.msg, { pauseOnHover: false });
          refreshPlan(planId)
          getListInfo()
        }
        else {
          toast.error(response?.data?.msg, { pauseOnHover: false });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {createPortal(
        <>
          <ToastContainer />
          {editListOpen && (
            <div className="bg-gray-700/75 w-full  h-[100vh] fixed left-0 top-0 z-40 ">
              <div
                className="fixed overflow-y-scroll h-[70%] left-1/2 top-[50%] -translate-x-1/2 -translate-y-1/2 bg-white border-2 border-gray-500 p-4 sm:w-3/4 md:w-3/4 lg:w-1/2 2xl:w-1/3 rounded-xl shadow-md"
                onClose={() => setEditListOpen(false)}
              >
                <div className="flex my-2 justify-between items-center">
                  <h1 className="text-[#323237] text-lg font-semibold mb-4 text">
                    Edit List
                  </h1>

                </div>

                <div>
                  <div className="mb-2 block">
                    <Label
                      className="mb-2 text-[#3D3D3D]"
                      htmlFor="listname"
                      value="List Name"

                    />
                  </div>
                  <TextInput
                    id="listname"
                    placeholder=""
                    required
                    type="text"
                    value={listName}
                  />

                  <div className="mt-4">
                    <Label className="text-[#3D3D3D]" value="Choose Platform" />
                    <div className=" mt-2 flex justify-left flex-wrap bg-[#D9D9D9] w-fit rounded-[4px] p-2">
                      {platforms &&
                        platforms.map((platform) => {
                          const Icon = BSIcon[`Bs${capitalise(platform)}`];
                          return (
                            <Button
                              color="gray"
                              className=" border-none focus:ring-0"
                              // onClick={() => handlePlatform(platform)}
                              style={{
                                backgroundColor: selectedPlatforms?.includes(platform.toLowerCase())
                                  ? "#050505"
                                  : "#D9D9D9",
                                outline: "none",
                                border: "none",
                                ":hover": {
                                  boxShadow: "0 0 5px #00aaff",
                                },
                                color: selectedPlatforms?.includes(platform) ? "white" : "gray",
                              }}
                            >

                              {selectedPlatforms.includes("instagram") && platform === "instagram" ? (
                                <img src={ActiveInstagram} className="mr-2 object-contain h-5 w-5" alt="Active Instagram Icon" />
                              ) :
                                selectedPlatforms.includes("youtube") && platform === "youtube" ? (
                                  <img src={Activeyoutube1} className="mr-2 object-contain h-5 w-5" alt="Active Instagram Icon" />
                                ) :
                                  selectedPlatforms.includes("facebook") && platform === "facebook" ? (
                                    <img src={Activefacebook} className="mr-2 object-contain h-5 w-5" alt="Active Instagram Icon" />
                                  ) : (
                                    <Icon className="mr-2 h-5 w-5" />
                                  )}
                              {capitalise(platform)}
                            </Button>
                          );
                        })}

                    </div>
                  </div>

                  {!selectedPlatforms?.length == 0 && (
                    <>
                      <div className="mt-6">
                        <div className="flex items-center gap-1">
                          <input
                            type="checkbox"
                            id="deliverables"
                            className="rounded-[4px] bg-gray-100 border border-gray-300 w-4 h-4 mr-1"
                            checked={isDeliverableChecked}
                            onChange={() =>
                              handleDeliverableCheck()
                            }
                          />

                          <label htmlFor="deliverables">Deliverables</label>
                        </div>
                        <p className="text-xs pl-6 text-gray-500">
                          * Deliverables, Posts & Costs will be applied to all
                          the profiles in the list
                        </p>
                      </div>
                      <p className="text-xs rounded-lg text-[#323237] mt-4 p-2 bg-amber-100">
                        Setting delivables for your plan allows you to reach out
                        to the influencers to get the cost and get access to
                        metrics like Estimated video views, CPE, and CPV.
                      </p>
                    </>
                  )}
                  {isDeliverableChecked && (
                    <div className="flex gap-2 mt-4 ">
                      <div id="select">{/*  */}</div>


                      <div className="flex flex-col gap-2 overflow-y-scroll h-[100px]">
                        {deliverables &&
                          deliverables?.map((deliv, index) => {
                            return (
                              <div
                                key={deliv.id}
                                className="flex gap-2 items-center"
                              >
                                <Select
                                  id="deliverables"
                                  required={true}
                                  value={deliv.cat_id}
                                  onChange={(e) => handleSelectChange(index,
                                    deliv.id,
                                    e.target.id,
                                    e.target.value)}

                                >
                                  {console.log({ deliverableOptions })}
                                  <option disabled>Select a deliverable</option>
                                  {deliverableOptions &&
                                    deliverableOptions?.map((deliverable, index) => {
                                      return (
                                        <option
                                          key={deliverable.id}
                                          value={deliverable.id}
                                          // disabled={deliverable.disabled} // Add disabled attribute if option is disabled
                                        >
                                          {deliverable.deliverable}
                                        </option>
                                      );
                                    })}
                                </Select>
                                <input
                                  type="text"
                                  placeholder="Posts"
                                  id="posts"
                                  autoComplete="off"
                                  className="w-28 rounded-md"
                                  value={deliv.posts}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      deliv.id,
                                      e.target.id,
                                      e.target.value
                                    )
                                  }
                                />

                                <input
                                  type="text"
                                  placeholder="Cost(Optional)"
                                  autoComplete="off"
                                  className="w-30 rounded-md"
                                  id="amount"
                                  value={deliv.amount}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      deliv.id,
                                      e.target.id,
                                      e.target.value
                                    )
                                  }
                                />
                                <CiSquareRemove
                                  className="text-3xl cursor-pointer hover:bg-gray-400 p-[2px] rounded-sm hover:text-white "
                                  onClick={(e) =>
                                    handleDeliveryDelete(index, deliv.id)
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}

                  {isDeliverableChecked && <Button
                    color="gray"
                    size="md"
                    className="flex items-center border-[#D9D9D9] cursor-pointer mt-3 py-1 px-1 border-[1px] text-black bg-[white] hover:opacity-90 rounded-md duration-100"
                    onClick={handleAddMore}
                  >
                    <AiOutlinePlus className="mr-1" />
                    Add More
                  </Button>
                  }

                </div>
                <div className="flex mt-8 gap-2">
                  <button
                    className="flex items-center justify-center rounded-md text-md bg-[#686FFD] text-white font-[600] py-3 px-6 mr-3"
                    onClick={updateList}
                    disabled={listName == "" || selectedPlatforms?.length == 0}
                  >
                    Update List
                  </button>
                  <button
                    className="flex whitespace-nowrap w-[90%] nowrap items-center rounded-[4px] hover:opacity-90 h-[53px] w-auto text-black  px-[24px] border-[0.8px] mr-3 border-[#D9D9D9]"
                    onClick={() => setEditListOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </>,

        document.body
      )}
    </>
  );
};

export default EditListDetails;
