import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  setEngAndEngRate,
  setEngRateFilter,
} from "../../redux/filters/actions";
import { IoChevronDownSharp } from "react-icons/io5";
import InfoIconTooltip from "./InfoIconTooltip";
import { MdCampaign } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const engRateOptions = ["Clear", ">1.0%", ">2.0%", ">3.0%", ">4.0%", ">5.0%", ">6.0%", ">7.0%", ">8.0%", ">9.0%", ">10.0%", ">11.0%", ">12.0%", ">13.0%", ">14.0%", ">15.0%", ">16.0%", ">17.0%", ">18.0%", ">19.0%", ">20.0%"];

const EngagementFilter = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const engagementRateUrl = searchParams.get("eng_rate")
  const engagementRateArray = engagementRateUrl ? engagementRateUrl.split(",") : [] 

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const [searchParam, setSearchParam] = useSearchParams();
  const selected_engAndEngrate = useSelector((state) => state.engAndEngrate);
  const [rangeValues, setRangeValues] = useState(
    selected_engAndEngrate || [null, null]
  );
  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);
  const [toDropdownOpen, setToDropdownOpen] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const selectedEngagementRate = useSelector(
    (state) => state.engagementRate[0]
  );
  const [rangeEngValue, setRangeEngValue] = useState(0.01); // Set default initial value
  const [manualInput, setManualInput] = useState("0.01"); // Set default initial value

  const handleChange = (e) => {
    const value = parseFloat(e.target.value);
    setRangeEngValue(value);
    // setManualInput(value.toString());

    dispatch(setEngRateFilter([value]));
    if (value) {
      setSearchParam((searchParams) => {
        searchParams.set("eng_rate", [value]);
        return searchParams;
      });
    }else{
      setSearchParam((searchParams) => {
        searchParams.delete("eng_rate");
        return searchParams;
      });
    }
  };

  const handleManualInputChange = (e) => {
    const selectedValue = e.target.value;
    // setManualInput(e.target.value);
    dispatch(setEngRateFilter([selectedValue]));
    setSearchParam((searchParams) => {
      searchParams.set("eng_rate", [selectedValue]);
      return searchParams;
    });
  };

  const handleManualInputBlur = () => {
    const parsedValue = parseFloat(manualInput);
    if (!isNaN(parsedValue)) {
      setRangeEngValue(parsedValue);
      dispatch(setEngRateFilter([parsedValue])); // Ensure you dispatch an array
    
      setSearchParam((searchParams) => {
        searchParams.set("eng_rate", [parsedValue]);
        return searchParams;
      });
    
    }
  };

  useEffect(() => {
    if (selectedEngagementRate !== undefined) {
      setRangeEngValue(selectedEngagementRate);
      setManualInput(selectedEngagementRate.toString());
    }
  }, [selectedEngagementRate]);

  const convertToNumeric = (value) => {
    const conversions = {
      "1k": 1000,
      "5k": 5000,
      "10k": 10000,
      "25k": 25000,
      "50k": 50000,
      "100k": 100000,
      "250k": 250000,
      "500k": 500000,
      ">1m": 1000000,
      "<1m": 1000000,
      Clear: null, // Return null for the "Clear" option
    };

    return conversions[value] !== undefined
      ? conversions[value]
      : parseInt(value);
  };

  const handleFromChange = (value) => {
    if (value === "Clear") {
      setRangeValues([null, rangeValues[1]]);
    } else {
      setRangeValues([convertToNumeric(value), rangeValues[1]]);
    }
    setFromDropdownOpen(false);
  };

  const handleToChange = (value) => {
    if (value === "Clear") {
      setRangeValues([rangeValues[0], null]);
    } else {
      setRangeValues([rangeValues[0], convertToNumeric(value)]);
    }
    setToDropdownOpen(false);
  };

  const fromOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    ">1m",
  ];
  const toOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    "<1m",
  ];

  const handEngRateChange = (value) => {
    console.log(value, 'valvalueue')
    if(value === 'Clear'){
      dispatch(setEngRateFilter([]));

      setSearchParam((searchParams) => {
        searchParams.delete("eng_rate");
        return searchParams;
      });
    }else{
      dispatch(setEngRateFilter([value]));

      if ([value].length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("eng_rate", [value]);
          return searchParams;
        });
      }
    }
    setDropdownOpen(false)
  }

  useEffect(() => {
    // Filter out null values if both "From" and "To" are null
    const sanitizedValues = rangeValues.filter((value) => value !== null);
    dispatch(setEngAndEngRate(sanitizedValues));

    if (sanitizedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("engagements", sanitizedValues);
        return searchParams;
      });
    }
  }, [dispatch, rangeValues]);

  return (
    <>
      <div ref={buttonRef} className="EngagementFilter flex justify-start">
        <div className="relative w-full">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="filterTextColor flex items-center bg-white"
          >
            <span
              className={` ${
                isDropdownOpen ? "font-semibold" : ""
              } flex items-center`}
            >
              Engagement
              {isDropdownOpen ? (
                <RxCross2 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2  text-lg" />
              )}
            </span>
          </button>
          <ul
            ref={dropdownRef}
            className={`absolute w-[250px] z-50 p-5 bg-white rounded-lg shadow-lg ${
              isDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Engagements
                  <InfoIconTooltip infoContent="Identify influencers by the keywords they use in their posts texts." />
                </div>
                <div className="flex gap-2 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      className="w-full py3 rounded-md border-[0.6px] border-[#D9D9D9]"
                      placeholder="From"
                      value={
                        rangeValues[0] !== null
                          ? fromOptions.find(
                              (key) => convertToNumeric(key) === rangeValues[0]
                            ) || ""
                          : ""
                      }
                      onClick={() => setFromDropdownOpen(!fromDropdownOpen)}
                      readOnly
                    />
                    {fromDropdownOpen && (
                      <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                        {fromOptions.map((option) => (
                          <div
                            key={option}
                            className="p-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => handleFromChange(option)}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {rangeValues[0] !== 1000000 && (
                    <div className="relative">
                      <input
                        type="text"
                        className="w-full py3 rounded-md border-[0.6px] border-[#D9D9D9]"
                        placeholder="To"
                        value={
                          rangeValues[1] !== null
                            ? toOptions.find(
                                (key) =>
                                  convertToNumeric(key) === rangeValues[1]
                              ) || ""
                            : ""
                        }
                        onClick={() => setToDropdownOpen(!toDropdownOpen)}
                        readOnly
                      />
                      {toDropdownOpen && (
                        <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                          {toOptions.map((option) => (
                            <div
                              key={option}
                              className="p-2 cursor-pointer hover:bg-gray-200"
                              onClick={() => handleToChange(option)}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Engagement Rate
                  <InfoIconTooltip infoContent="Identify influencers by the keywords they use in their posts texts." />
                </div>
                <div className="relative flex flex-col gap-2 w-full">
                <input
                  type="text"
                  className="w-full py-3.5 rounded-md border-[0.6px] border-[#D9D9D9]"
                  placeholder="From"
                  value={engagementRateArray}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  readOnly
                />
                {dropdownOpen && (
                  <div className="absolute max-h-[150px] overflow-auto top-full left-0 w-full bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                    {engRateOptions.map((option) => (
                      <div
                        key={option}
                        className="p-2 cursor-pointer text-gray-600 hover:bg-[#6d5eac] hover:text-white"
                        onClick={() => handEngRateChange(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
                  {/* <input
                    style={{
                      background: `linear-gradient(to right, #6d5eac 0%, #6d5eac ${
                        (engagementRateArray / 100) * 100
                      }%, #d7dcdf ${
                        (engagementRateArray / 100) * 100
                      }%, #d7dcdf 100%)`,
                    }}
                    onChange={handleChange}
                    type="range"
                    min="0"
                    max="100"
                    value={engagementRateArray}
                  />
                  <div className="flex gap-1">
                    <input
                      type="text"
                      value={engagementRateArray}
                      onChange={handleManualInputChange}
                      onBlur={handleManualInputBlur}
                      className="w-16 h-8 text-xs border border-gray-300 rounded-md px-2"
                    />
                    {engagementRateArray > 0 ? (
                      <p className="h-8 w-10 flex justify-center text-xs shadow-lg bg-[#1dbb99] text-white rounded-md items-center">
                        {engagementRateArray}%
                      </p>
                    ) : (
                      <p className="h-8 w-12 flex justify-center text-xs shadow-lg bg-[#1dbb99] text-white rounded-md items-center">
                        Set Eng.
                      </p>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default EngagementFilter;
